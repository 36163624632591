import * as React from "react"

// styles
const pageStyles = {
  color: "#232129",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 32,
  maxWidth: 320,
}
const paragraphStyles = {
  marginBottom: 48,
}
const listStyles = {
  marginBottom: 96,
  paddingLeft: 0,
}
const listItemStyles = {
  fontWeight: "300",
  fontSize: "24px",
  maxWidth: "560px",
  marginBottom: 32,
}

const linkStyle = {
  color: "#8954A8",
  fontWeight: "bold",
  fontSize: "16px",
  verticalAlign: "5%",
}

const descriptionStyle = {
  color: "#232129",
  fontSize: "14px",
}

// data
const links = [
  {
    text: "Storyline Bookkeeping",
    url: "https://www.storylinebookkeeping.com",
    description:
      "Monthly bookkeeping services for small businesses.",
    color: "#E95800",
  },
  {
    text: "Atreeva",
    url: "https://www.atreeva.com",
    description:
      "DataOps platform for companies that depend on external data.",
    color: "#E95800",
  },
  /*
  {
    text: "Ordertastic",
    url: "https://ordertastic.com",
    description:
      "Leading site-search and merchandising for online retailers",
    color: "#1099A8",
  },
  {
    text: "SalesGust",
    url: "https://salesgust.com",
    description:
      "Leading site-search and merchandising for online retailers",
    color: "#1099A8",
  },
  {
    text: "SearchSpring (exited)",
    url: "https://searchspring.com",
    description:
      "Site search and merchandising for online retailers.",
    color: "#1099A8",
  }
  */
]

// markup
const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <title>Sebenza</title>
      <h1 style={headingStyles}>
        Sebenza
      </h1>
      <p style={paragraphStyles}>
        We incubate and invest in technology companies.
      </p>
      <ul style={listStyles}>
        {links.map(link => (
          <li key={link.url} style={{ ...listItemStyles, color: link.color }}>
            <span>
              <a
                style={linkStyle}
                href={`${link.url}`}
              >
                {link.text}
              </a>
              <p style={descriptionStyle}>{link.description}</p>
            </span>
          </li>
        ))}
      </ul>
    </main>
  )
}

export default IndexPage
